import { RequestConfig } from 'umi';
import { decamelizeKeys } from 'humps';
import { local } from '@/utils/storage';

/**
 * Decamelize Request Params Keys
 * @param url
 * @param options
 */
const decamelizeParams = (url: string, options: any) => {
  const { currentLang = globalThis.initLanguage } =
    local.getItem('globalState') || {};
  const { params, data, escapeDecamelize = false } = options;

  return {
    url,
    options: {
      ...options,
      params: escapeDecamelize
        ? { ...params, lang: currentLang }
        : { ...decamelizeKeys(params), lang: currentLang },
      data: escapeDecamelize ? data : decamelizeKeys(data),
    },
  };
};

export const request: RequestConfig = {
  prefix: API_URL,
  errorHandler: (error: any) => {
    throw error;
  },
  requestInterceptors: [decamelizeParams],
};

export const ssr = {
  modifyGetInitialPropsCtx: async (ctx: any) => {
    if (ctx.isServer) {
      globalThis.isMobile = ctx.isMobile;
      globalThis.initLanguage = ctx.initLanguage;
    }

    return ctx;
  },
};
