import { Spin } from 'antd';
import styles from './index.less';
import IglooIcon from '@/assets/igloo.gif';

const antIcon = <img src={IglooIcon} className={styles.loadingIcon} alt="" />;

// Return value should be component
const CustomSpinner = () => (
  <Spin
    indicator={antIcon}
    style={{ margin: '0 auto', display: 'block' }}
    data-testid="spinner"
  />
);

export default CustomSpinner;
